import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);

let instagramCarrousel;

window.addEventListener('load', function () {
	const instagramCarrouselId = document.getElementById('carrousel-instagram');
	const breakpoint = window.matchMedia('(min-width:768px)');

	function breakpointChecker() {
		if (breakpoint.matches === true) {
			if (instagramCarrousel !== undefined && document.body.contains(instagramCarrouselId)) {
				instagramCarrousel.destroy(true, true);
			}
		} else {
			return enableSwiper();
		}
	}

	function enableSwiper() {
		instagramCarrousel = new Swiper(instagramCarrouselId, {
			slidesPerView: 1,
			spaceBetween: 0,
			watchOverflow: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			on: {
				resize: function () {
					if (breakpoint.matches === true) {
						instagramCarrousel.destroy(true, true);
					}
				},
			},
		});
	}

	breakpoint.addListener(breakpointChecker);
	breakpointChecker();
});
